import { useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import { isMobile } from 'react-device-detect'
import { match } from 'ts-pattern'
import { IAMPORT } from '@/constants/legacy/constEnv'
import ROUTES from '@/constants/legacy/constRoutes'
import { OrderTypeEnum } from '@/constants/order-type.enum'
import { IamPortBankCode, PaymentMethodTypeEnum } from '@/constants/payment-method-type.enum'
import { PaymentPgtypeEnum } from '@/constants/payment-pgtype.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCoupons } from '@/containers/coupon/useCoupons'
import { useAccountsEventTracker } from '@/containers/event-tracker/accounts.common.event-tracker'
import MUTATION_VARIABLES from '@/containers/gqls/base/mutation_variables'
import ORDER_QUERY_GQLS from '@/containers/gqls/orders/queries'
import { useApiMutation, useCartNew, useCustomRouter, useMessage } from '@/containers/hooks'
import { useAppendIamportScripts } from '@/containers/hooks/order/append-iamport-scripts.hook'
import { IModelOrder } from '@/containers/models/modelOrder'
import { IModelOrderItem } from '@/containers/models/modelOrderItem'
import { IModelTransaction } from '@/containers/models/modelTransaction'
import { UserContainer } from '@/containers/users/UserContainer'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { getResultFromData } from '@/utils/utilApi'
import { addHour, DATE_FORMAT_YYYY_MM_DD, DATE_FORMAT_YYYYMMDDHHmm, formatDate } from '@/utils/utilDate'
import { localTempTransactionId } from '@/utils/utilLocalStorage'

type OrderAddressType = { address: string; postcode: string }

type UseOrderPaymentType = {
  onPaymentFailed: (transactionId: string) => void
  requestCreateTransaction: (createOrderResult: any) => void
  requestApproveTransaction: (transactionId: string, orderType: OrderTypeEnum | undefined) => void
  trackPaymentEnd: (order: IModelOrder) => void
  setOrderAddressForIamportParam: (orderAddress: OrderAddressType) => void
  onPaymentMethodSelect: (method: PaymentMethodTypeEnum) => void
  getPaymentMethod: () => PaymentMethodTypeEnum
  getCriteoTrackTransactionData: (order: IModelOrder) => {
    orderId: string
    item: { id: string; price: number; quantity: number }[]
  }
}

const useOrderPayment = (): UseOrderPaymentType => {
  const { trackUserCouponMileageProperties } = useAccountsEventTracker()
  const orderAddressRef = useRef<OrderAddressType>()
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodTypeEnum>(PaymentMethodTypeEnum.TossPay)
  const tracker = useTracker()
  const { replace, query } = useCustomRouter()
  const { orderType } = query as { orderType: OrderTypeEnum | undefined }
  const { show } = useMessage()
  const { me } = UserContainer.useContainer()
  const beforeHistoryLength = useRef(0)
  const { refreshUserInfo } = UserContainer.useContainer()
  const { couponGroups } = useCoupons()

  useAppendIamportScripts()

  const { refreshCartProductOptionsCount } = useCartNew()

  // ====================================== onPaymentFailed ======================================
  const _trackPaymentFailed = (res: any) => {
    if (!res) return
    const _transaction = getResultFromData(res)!.transaction.data as IModelTransaction
    const { amount, order } = _transaction
    const { _id, getAllProductIdAndName } = order

    const trackingData = {
      orderId: _id,
      totalPaymentAmount: amount,
      products: getAllProductIdAndName(),
    }
    tracker.triggerCustomEvent(FitpetMallEventEnum.CompletePurchaseError, trackingData)
  }

  const [transactionForTrackingFailureQuery] = useLazyQuery(ORDER_QUERY_GQLS.TRANSACTION_FOR_TRACKING_FAILURE, {
    onCompleted: _trackPaymentFailed,
  })

  const onPaymentFailed = async (transactionId: string) => {
    transactionForTrackingFailureQuery({ variables: { id: transactionId } })
  }
  // ====================================== onPaymentFailed ======================================

  const _trackPaymentStart = (order: IModelOrder) => {
    const isSinglePurchase = orderType === OrderTypeEnum.BuyNow
    const cartTrackingData = order.getPaymentTrackingData(paymentMethod)
    const buyNowTrackingData = {
      ...cartTrackingData,
      isSinglepurchase: isSinglePurchase,
    }
    const trackingData = isSinglePurchase ? buyNowTrackingData : cartTrackingData

    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickOrderPaymentButton, {
      afCurrency: 'KRW',
      afTotalPaymentAmount: trackingData.totalPaymentAmount,
      ...trackingData,
    })
  }

  const trackPaymentEnd = (order: IModelOrder) => {
    const isSinglePurchase = orderType === OrderTypeEnum.BuyNow
    const cartTrackingData = order.getPaymentTrackingData(paymentMethod)
    const buyNowTrackingData = {
      ...cartTrackingData,
      isSinglepurchase: isSinglePurchase,
    }
    const trackingData = isSinglePurchase ? buyNowTrackingData : cartTrackingData
    const { isFirstPurchase = false } = order
    const { totalPaymentAmount } = trackingData

    console.log(totalPaymentAmount)

    const latestOrderAt = order.getLastTransaction().updatedAt

    if (isFirstPurchase) {
      tracker.triggerCustomEvent(FitpetMallEventEnum.FirstCompletePurchase, {
        afCurrency: 'KRW',
        afPrice: totalPaymentAmount,
        ...trackingData,
      })
    }

    tracker.triggerCustomEvent(FitpetMallEventEnum.CompletePurchase, {
      afCurrency: 'KRW',
      afRevenue: totalPaymentAmount,
      afPrice: totalPaymentAmount,
      ...trackingData,
    })

    trackUserCouponMileageProperties({
      coupons: couponGroups,
      mileage: me?.mileagePoint || 0,
    })

    // update lastOrderedAt from latestOrderAt
    if (latestOrderAt) {
      tracker.setUserProperties({ last_order_date: formatDate(latestOrderAt, DATE_FORMAT_YYYY_MM_DD) })
      tracker.triggerCustomEvent(FitpetMallEventEnum.Garbage)
    }
  }

  /**
   * 크리테오 트래킹
   */
  const getCriteoTrackTransactionData = (order: IModelOrder) => {
    const isSinglePurchase = orderType === OrderTypeEnum.BuyNow
    const cartTrackingData = order.getPaymentTrackingData(paymentMethod)
    const buyNowTrackingData = {
      ...cartTrackingData,
      isSinglepurchase: isSinglePurchase,
    }
    const trackingData = isSinglePurchase ? buyNowTrackingData : cartTrackingData

    // 데이터 오류 방지를 위해 try catch 적용
    try {
      const item =
        trackingData?.products.map((product: any) => ({
          id: product.productId as string,
          price: product?.productOptions[0]?.price || 0,
          quantity: product?.productOptions[0]?.productOptionQuantity || 0,
        })) || []

      return { item, orderId: trackingData.orderId }
    } catch (e) {
      return { item: [], orderId: 'criteo data error' }
    }
  }

  const _getPgType = (paymentMethod: PaymentMethodTypeEnum) => {
    switch (paymentMethod) {
      case PaymentMethodTypeEnum.KakaoPay:
        return PaymentPgtypeEnum.KakaoPay
      case PaymentMethodTypeEnum.NaverPay:
        return PaymentPgtypeEnum.NaverPay
      case PaymentMethodTypeEnum.TossPay:
        return PaymentPgtypeEnum.TossPay
      case PaymentMethodTypeEnum.Payco:
        return PaymentPgtypeEnum.Payco
      default:
        return PaymentPgtypeEnum.Uplus
    }
  }

  const _getNaverPayProperties = (pgType: PaymentPgtypeEnum, transaction: IModelTransaction) => {
    if (pgType !== PaymentPgtypeEnum.NaverPay) {
      return {}
    }

    const _orderItems = transaction?.order?.orderOrderItems
    return {
      naverProducts: _orderItems.map((orderItem: IModelOrderItem) => {
        return {
          categoryType: 'PRODUCT',
          categoryId: 'GENERAL',
          uid: `${orderItem?.productOption?.productId || ''}`,
          name: orderItem?.productOption?.name || '',
          count: (orderItem?.quantity as number) || 0,
        }
      }),
    }
  }

  const _checkoutIamport = (createTrxResult: any) => {
    if (!createTrxResult) {
      return
    }
    const transaction = createTrxResult.transaction.data as IModelTransaction
    const { amount, id, merchantUid, name: orderName } = transaction

    localTempTransactionId.save(id)

    if (amount === 0) {
      requestApproveTransaction(id, orderType)
      //적립금 100% 결제
      return
    }

    const { name, email, mobileNumber = '' } = me || {}
    // @ts-ignore
    const IMP = window.IMP
    IMP.init(IAMPORT.MERCHANT_CODE)

    const pgType = _getPgType(paymentMethod)
    const _naverPayProperties = _getNaverPayProperties(pgType, transaction)

    // 신용카드 결제수단 선택화면에서 kb pay만 활성화
    const kbPayProperties =
      paymentMethod === PaymentMethodTypeEnum.KbPay
        ? {
            card: {
              detail: [
                { card_code: '*', enabled: false },
                { card_code: IamPortBankCode.KB, enabled: true },
              ],
            },
          }
        : {}

    const getPaymentMethod = (paymentMethod: PaymentMethodTypeEnum) => {
      return match(paymentMethod)
        .with(PaymentMethodTypeEnum.KbPay, () => PaymentMethodTypeEnum.CreditCard)
        .otherwise(() => paymentMethod)
    }

    const redirectUrl = `${window.location.origin}${ROUTES.PREFIX}${ROUTES.ORDER.REDIRECT}?orderType=${orderType}&beforeHistoryLength=${beforeHistoryLength.current}&transaction=${id}`

    const requestData: Record<any, any> = {
      pg: pgType,
      pay_method: getPaymentMethod(paymentMethod),
      merchant_uid: merchantUid,
      name: orderName,
      amount: amount,
      buyer_email: email,
      buyer_name: name || '핏펫회원',
      buyer_tel: mobileNumber,
      buyer_addr: orderAddressRef.current?.address,
      buyer_postcode: orderAddressRef.current?.postcode,
      custom_data: {
        transactionId: id,
      },
      m_redirect_url: redirectUrl,
      biz_num: '5438700755',
      vbank_due: addHour(72, undefined, DATE_FORMAT_YYYYMMDDHHmm),
      ...kbPayProperties,
      ..._naverPayProperties,
    }

    /**
     * 가상계좌, 계좌이체는 escrow true
     */
    if (paymentMethod === PaymentMethodTypeEnum.VirtualAccount || paymentMethod === PaymentMethodTypeEnum.Bank) {
      requestData['escrow'] = true
    }

    IMP.request_pay(requestData, (paymentRes: any) => {
      if (paymentRes.success) {
        const { custom_data } = paymentRes
        const { transactionId } = custom_data

        requestApproveTransaction(transactionId, orderType)
      } else {
        const _trxId = paymentRes?.custom_data?.transactionId || localTempTransactionId.load()
        if (_trxId) {
          onPaymentFailed(_trxId)
        }
        show(paymentRes.error_msg || '에러가 발생하였습니다.')

        Sentry.withScope((scope) => {
          scope.setTag('errorType', 'Payment Error')
          scope.setExtra('orderType', orderType)
          scope.setExtra('transactionId', _trxId)
          scope.setExtra('errorMessage', paymentRes.error_msg)
          scope.setExtra('paymentResponse', paymentRes)

          // 추가 정보 설정
          scope.setExtra('pgType', requestData.pg)
          scope.setExtra('payMethod', requestData.pay_method)
          scope.setExtra('merchantUid', requestData.merchant_uid)
          scope.setExtra('orderName', requestData.name)
          scope.setExtra('amount', requestData.amount)
          scope.setExtra('buyerEmail', requestData.buyer_email)
          scope.setExtra('buyerName', requestData.buyer_name)
          scope.setExtra('buyerTel', requestData.buyer_tel)
          scope.setExtra('buyerAddr', requestData.buyer_addr)
          scope.setExtra('buyerPostcode', requestData.buyer_postcode)
          scope.setExtra('redirectUrl', requestData.m_redirect_url)
          scope.setExtra('bizNum', requestData.biz_num)
          scope.setExtra('vbankDue', requestData.vbank_due)

          Sentry.captureException(new Error('request_pay error'))
        })
      }
    })

    if (!isMobile) {
      window.scrollTo({ top: 0, behavior: 'auto' })
    }
  }

  const onCompleteApproveTransaction = () => {
    refreshUserInfo()
  }

  const [approveTransactionMutation] = useApiMutation('approveTransaction', onCompleteApproveTransaction)
  const requestApproveTransaction = async (transactionId: string, orderType: OrderTypeEnum = OrderTypeEnum.Cart) => {
    const variables = MUTATION_VARIABLES.APPROVE_TRANSACTION({
      id: transactionId,
      isDirect: orderType === OrderTypeEnum.BuyNow,
    })
    const { data, error } = await approveTransactionMutation({ variables })
    if (error) {
      // TODO: handle error
      return
    }

    const trxResult = getResultFromData(data.approveTransaction)
    if (trxResult!.transaction) {
      // @ts-ignore
      const { transaction } = trxResult

      localTempTransactionId.remove()
      await refreshCartProductOptionsCount()

      replace({
        pathname: ROUTES.ORDER.FINISH,
        query: {
          order_id: transaction.data.order.id,
          orderType: orderType,
        },
      })
    }
  }

  const [createTransactionMutation] = useApiMutation('createTransaction', _checkoutIamport)
  const requestCreateTransaction = (createOrderResult: any) => {
    if (!createOrderResult) {
      return
    }
    // TODO: remove debug log
    const order = createOrderResult.order.data as IModelOrder
    const variables = MUTATION_VARIABLES.CREATE_TRANSACTION({
      order: order.id,
      ...(paymentMethod ? { payMethodType: paymentMethod.toUpperCase() } : {}),
    })
    // @ts-ignore
    window.history.pushState(undefined, '', window.location.href)
    beforeHistoryLength.current = window.history.length - 1
    createTransactionMutation({ variables })
    _trackPaymentStart(order)
  }

  const setOrderAddressForIamportParam = (orderAddress: OrderAddressType) => {
    orderAddressRef.current = orderAddress
  }

  const onPaymentMethodSelect = (method: PaymentMethodTypeEnum) => {
    setPaymentMethod(method)
  }

  const getPaymentMethod = () => {
    return paymentMethod
  }

  return {
    onPaymentFailed,
    requestCreateTransaction,
    requestApproveTransaction,
    trackPaymentEnd,
    setOrderAddressForIamportParam,
    onPaymentMethodSelect,
    getPaymentMethod,
    getCriteoTrackTransactionData,
  }
}

export default useOrderPayment
